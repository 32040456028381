/* eslint-disable func-names */
<template>
  <CModal
    :show="value"
    @close="$emit('input', false)"
    submit-form="feedback-form"
    :submit-loading="loading"
  >
    <template v-slot:header>
      <CTypo tstyle="title3">{{ $t('sentFeedback') }}</CTypo>
    </template>

    <CForm 
      id="feedback-form" 
      @submit="sentFeedback" 
      :error-fields-response="errorFieldsResponse"
      custom-save-button
    >
      <CSelect
        v-model="data.type"
        :label="$t('feedback.type')"
      >
        <option value="feedback">{{ $t('feedback.feedback') }}</option>
        <option value="improvement">{{ $t('feedback.improvment') }}</option>
        <option value="error">{{ $t('feedback.error') }}</option>
      </CSelect>
      <CTextField
        v-model="data.content"
        :label="$t('feedback.note')"
        required
        type="textarea"
        rows="12"
      />
    </CForm>

    <template v-slot:submit>
      {{ $t('save') }}
    </template>
  </CModal>
</template>

<script>
import { feedback } from '@contimo/api/src/api';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        type: 'feedback',
        content: '',
      },
      errorFieldsResponse: null,
    };
  },
  watch: {
    value() {
      this.data = {
        type: 'feedback',
        content: '',
      };
    },
    data: {
      deep: true,
      handler() {
        this.errorFieldsResponse = null;
      },
    },
  },
  methods: {
    async sentFeedback() {
      this.loading = true;
      try {
        await feedback.store(this.data);
        this.$emit('input', false);
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.errorFieldsResponse = e.response;
        } else {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
