<template>
  <div class="flex flex-col justify-between min-h-full">
    <div>
      <slot name="toolbar" />
      <CNotification 
        v-model="showSuccessNotificaiton"
        icon="check-circle-outline" 
        close-icon="x" 
        icon-class="text-green-600"
      >
        {{ $t('successfullySaved') }}
      </CNotification>
      <Portal to="overlays">
        <CErrorNotification v-model="showErrorNotificaiton" />
      </Portal>
      <div>
        <slot />
      </div>
    </div>
    <div class="pb-safe-area">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ERROR_NOTIFICATION, SUCESS_NOTIFICATION } from '@/store/gettersTypes';
import { SET_ERROR_NOTIFICATION, SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  computed: {
    ...mapGetters([ERROR_NOTIFICATION, SUCESS_NOTIFICATION]),
    showSuccessNotificaiton: {
      get() {
        return this.successNotification.show;
      },
      set(value) {
        return this.setSuccessNotification({
          ...this.setSuccessNotification,
          show: value,
        });
      },
    },
    showErrorNotificaiton: {
      get() {
        return this.errorNotification.show;
      },
      set(value) {
        return this.setErrorNotification({
          ...this.errorNotification,
          show: value,
        });
      },
    },
  },
  methods: {
    ...mapMutations([SET_ERROR_NOTIFICATION, SET_SUCCESS_NOTIFICATION]),
  },
};
</script>
